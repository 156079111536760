<template>
  <div
    class="w-full relative"
    :class="[
      fullWidth === 'true' ? '' : 'lg:max-w-[396px]',
      disabledDark ? 'cursor-not-allowed' : '',
    ]"
    v-outside
  >
    <!-- disabled ? 'cursor-not-allowed opacity-50' : '', -->
    <div
      class="flex flex-col w-full cursor-default"
      :class="[
        fullWidth === 'true' ? 'w-full' : width ? width : 'md:max-w-[396px]',
        label ? 'gap-3' : '',
      ]"
      ref="dropdown"
    >
      <div class="flex items-center gap-3">
        <p
          class=""
          :class="
            (textClass ? textClass : 'leading-none',
            labelClasses
              ? labelClasses
              : 'font-semibold text-sm/none text-neutral-800 dark:text-white cursor-default')
          "
        >
          {{ label }}
          <span class="text-neutral-500" v-if="labelLight">
            {{ labelLight }}
          </span>
        </p>
        <svg
          v-if="toolTipText"
          class="text-neutral-800 dark:text-neutral-200 cursor-pointer"
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.5625 6.5625L6.5867 6.5504C6.92102 6.38324 7.29745 6.6852 7.2068 7.04782L6.7932 8.70218C6.70255 9.0648 7.07898 9.36676 7.4133 9.1996L7.4375 9.1875M12.25 7C12.25 9.89949 9.89949 12.25 7 12.25C4.10051 12.25 1.75 9.89949 1.75 7C1.75 4.10051 4.10051 1.75 7 1.75C9.89949 1.75 12.25 4.10051 12.25 7ZM7 4.8125H7.00438V4.81688H7V4.8125Z"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div
        class="relative custom-add w-full"
        @click="!disabled && handleShowDropdown()"
      >
        <!-- @click="showDropdown = !showDropdown" -->
        <span
          v-if="imageIcon && selectedOption && selectedOption.type"
          class="absolute top-3.5 left-2"
        >
          <img
            :src="`${cdnURL}${
              selectedOption.title == 'GOTOWEBINAR'
                ? 'gotowebinar'
                : selectedOption.title == 'OBVIO'
                ? 'obvio'
                : selectedOption.title == 'ZOOM'
                ? 'zoom'
                : selectedOption.type.toLowerCase()
            }.png`"
            alt=""
            class="w-5 h-5"
            :class="customRounded ? 'rounded-none' : 'rounded-full'"
          />
        </span>
        <ReusableUIInput
          type="text"
          full-width="true"
          no-transition="true"
          truncate="true"
          :moreClass="[
            error
              ? 'border-red-500'
              : moreClass
              ? moreClass
              : disabled
              ? 'cursor-not-allowed'
              : disabledDark
              ? 'cursor-not-allowed bg-zinc-100 text-zinc-400'
              : 'border-neutral-300 dark:border-neutral-700 cursor-pointer',
            customPadding ? customPadding : ' p-3 ',
            clicked ? 'border-blue-600' : '',
            'py-3 !caret-transparent select-none',
            imageIcon && selectedOption && selectedOption.type
              ? ' px-8'
              : 'px-4',
          ]"
          :onkeypress="!disabled && handleSelectChange"
          :model-value="
            selectedOption
              ? selectedOption.title
              : optionlabel
              ? optionlabel
              : 'Select an option'
          "
          :dusk="selector ? selector : label"
        />
        <div
          v-if="
            selectedOption &&
            !disabled &&
            !disabledDark &&
            showCross == true &&
            !helper
          "
          @click.stop="onDropdownClick('')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-4 h-4 text-neutral-600 dark:text-neutral-400 cursor-pointer absolute top-3.5 right-[33px]"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        </div>
        <div v-if="helper">
          <svg
            :dusk="`Action${
              selectedOption?.title == 'GOTOWEBINAR'
                ? 'gotowebinar'
                : selectedOption?.title == 'OBVIO'
                ? 'obvio'
                : selectedOption?.title == 'ZOOM'
                ? 'zoom'
                : selectedOption?.type?.toLowerCase()
            }`"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.4"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="w-4.5 h-4.5 text-neutral-600 dark:text-neutral-400 cursor-pointer absolute top-3.5 right-9"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
            <path d="M12 17h.01"></path>
          </svg>
        </div>
        <svg
          v-if="!disabled && !disabledDark"
          width="16"
          class="text-neutral-600 dark:text-neutral-400 absolute right-[13px] transition-all ease-in-out duration-300 cursor-pointer"
          :class="[
            showDropdown ? 'rotate-180' : '',
            customPosition ? customPosition : 'top-3.5',
          ]"
          height="16"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          @click="clicked = true"
        >
          <path
            d="M16.25 6.875L10 13.125L3.75 6.875"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <!-- <div
        class="flex items-center bg-transparent"
        @click="showDropdown = !showDropdown"
      >
        <div
          class="inline-flex w-full items-center justify-between gap-x-1.5 rounded-md bg-white text-sm font-semibold text-neutral-600 border dark:bg-neutral-800 outline-none hover:border-neutral-400 transition-all ease-in-out duration-150"
          @click="clicked = true"
          :class="[
            error
              ? 'border-red-500'
              : moreClass
              ? moreClass
              : disabled
              ? 'cursor-not-allowed'
              : 'border-neutral-300 dark:border-neutral-700',
            customPadding ? customPadding : ' p-3 ',
            clicked ? 'border-blue-600' : '',
          ]"
        >
          <p
            class="font-normal text-sm leading-[150%] dark:text-neutral-400 select-none"
            :class="[
              fit
                ? '!truncate !max-w-[396px] !w-full'
                : 'flex items-center gap-1',
              selectedOption ? 'text-neutral-600' : 'text-neutral-400',
            ]"
          >
            <span v-if="imageIcon && selectedOption && selectedOption.type">
              <img
                :src="`${cdnURL}${selectedOption.type.toLowerCase()}.png`"
                alt=""
                class="w-5 h-5 rounded-full"
              />
            </span>
            {{
              selectedOption
                ? selectedOption.title
                : optionlabel
                ? optionlabel
                : "Select an option"
            }}
          </p>
          <svg
            width="20"
            class="text-neutral-600 dark:text-neutral-400"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16.25 6.875L10 13.125L3.75 6.875"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div> -->
    </div>
    <div
      v-if="showDropdown"
      class="z-10 bg-white border dark:border-neutral-700 rounded-lg w-full dark:bg-neutral-800 mt-2 absolute h-fit max-h-[200px] overflow-y-auto content-scroll"
      :class="
        ((disabled || disabledDark) && 'hidden',
        showDropdownPosition == 'top' ? 'bottom-[54px]' : '')
      "
    >
      <ul class="py-1 text-sm text-gray-700 dark:text-gray-200 select-none">
        <li
          v-if="
            filterClear || options == null || Object.keys(options).length == 0
          "
          @click.prevent="
            [(showDropdown = false), filterClear && onDropdownClick('')]
          "
          class="px-4 py-3 font-normal text-sm leading-none text-neutral-600 dark:text-neutral-200 hover:bg-blue-50 dark:hover:bg-blue-400/10 hover:text-blue-600 dark:hover:text-blue-400 cursor-pointer"
          :class="filterList ? 'border-b dark:border-neutral-700' : ''"
        >
          {{ filterClear ? "None" : "No options available" }}
        </li>
        <p
          :title="item.title"
          v-for="item in sortOptions()"
          :key="item.value"
          :style="fontFamily && `font-family:${item.value}`"
          @click.prevent="
            disableOptions && disableOptions.includes(item.title)
              ? ''
              : onDropdownClick(item)
          "
          class="px-4 py-3 font-normal text-sm leading-none truncate text-neutral-600 dark:text-neutral-200 hover:bg-blue-50 dark:hover:bg-blue-400/10 hover:text-blue-600 dark:hover:text-blue-400 cursor-pointer"
          :class="[
            fit
              ? '!truncate !max-w-[396px] !w-full'
              : 'flex items-center gap-1',
            disableOptions && disableOptions.includes(item.title)
              ? '!cursor-not-allowed p-4'
              : '',
            item.hidden ? 'hidden' : '',
          ]"
          :dusk="item.title"
          :data-value="item.value"
        >
          <span v-if="imageIcon && item.type">
            <img
              :src="`${cdnURL}${
                item.title == 'GOTOWEBINAR'
                  ? 'gotowebinar'
                  : item.title == 'OBVIO'
                  ? 'obvio'
                  : item.title == 'ZOOM'
                  ? 'zoom'
                  : item.type.toLowerCase()
              }.png`"
              alt=""
              class="w-5 h-5"
              :class="customRounded ? 'rounded-none' : 'rounded-full'"
            />
          </span>
          {{ item.title }}
        </p>
      </ul>
    </div>
  </div>
</template>

<script setup>
const emit = defineEmits(["change", "update:modelValue"]);
const clicked = ref(false);
// Props
const props = defineProps({
  label: String,
  toolTipText: String,
  options: { type: Object, default: {} },
  labelLight: String,
  fullWidth: String,
  error: { type: Boolean, default: false },
  moreClass: String,
  textClass: String,
  customPadding: String,
  width: String,
  modalvalue: String,
  filterClear: String,
  filterList: String,
  imageIcon: Boolean,
  optionlabel: { type: String, default: "Select an option" },
  disabled: {
    type: Boolean,
    default: false,
  },
  disabledDark: {
    type: Boolean,
    default: false,
  },
  value: { type: [String, Number, Array], default: null },
  fit: {
    type: Boolean,
    default: false,
  },
  disableOptions: { type: Array, default: null },
  customRounded: Boolean,
  labelClasses: String,
  notSorted: { type: Boolean, default: false },
  showCross: { type: Boolean, default: true },
  fontFamily: { type: Boolean, default: false },
  customPosition: String,
  selector: { type: String, default: null },
  helper: {
    type: Boolean,
    default: false,
  },
  sortByValue: {
    type: Boolean,
    default: false,
  },
});
const showDropdown = ref(false);
const showDropdownPosition = ref("bottom");
const config = useRuntimeConfig();
const cdnURL = config.public.cdnURL;

// Refs
let selectedOption = ref(null);
let dropdown = ref(null);

// Methods

const loadSettings = () => {
  const val = props?.value;
  if (val != null && props.options != null) {
    selectedOption.value =
      props.options[val] != undefined && props.options[val] != null
        ? props.options[val]
        : {};

    emit("update:modelValue", selectedOption.value.value || "");
  }
};

const handleShowDropdown = () => {
  showDropdown.value = !showDropdown.value;

  if (dropdown?.value) {
    let temp = dropdown.value.getBoundingClientRect().bottom;
    let spaceBelow = window.innerHeight - temp;
    showDropdownPosition.value = spaceBelow < 250 ? "top" : "bottom";
  }
};
const onDropdownClick = (item) => {
  if (item == "none") {
    selectedOption.value = null;
  } else {
    selectedOption.value = item;
  }
  emit("update:modelValue", item.value || null);
  emit("change", item.value, item.title);
  showDropdown.value = false;
};

const vOutside = {
  mounted(el, binding, vnode) {
    const onClickOutside = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        showDropdown.value = false;
      }
    };
    setTimeout(() => {
      document.body.addEventListener("click", onClickOutside);
      el._onClickOutside = onClickOutside;
    }, 2000);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el._onClickOutside);
    delete el._onClickOutside;
  },
};

watch(
  [() => props.value, () => dropdown.value],
  () => {
    // let temp = dropdown.value;
    selectedOption.value = null;
    loadSettings();
  },
  { immediate: true }
);
const handleSelectChange = (val) => {
  if (val.key != "Enter") return false;
  if (val.key == "Enter") {
    showDropdown.value = !showDropdown.value;
  }
};

const sortOptions = () => {
  if (props.notSorted && !props.sortByValue) {
    return props.options;
  } else if (props.sortByValue) {
    let data = props.options;
    // Extract titles and values into an array of objects
    const dataArray = Object.values(data);

    // Sort the array by title alphabetically
    dataArray.sort((a, b) => a.value - b.value);
    return dataArray;
  } else {
    let data = props.options;
    // Extract titles and values into an array of objects
    const dataArray = Object.values(data);

    // Sort the array by title alphabetically
    dataArray.sort((a, b) => a.title.localeCompare(b.title));

    return dataArray;
  }
};

// document.onkeydown = checkKey;

// function checkKey(e) {
//   e = e || window.event;
//   if (e.keyCode == "38") {
//     // up arrow
//   } else if (e.keyCode == "40") {
//     // down arrow
//   } else if (e.keyCode == "37") {
//     // left arrow
//   } else if (e.keyCode == "39") {
//     // right arrow
//   }
// }
</script>
